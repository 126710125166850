import React from 'react';
import './Footer.css';
import villapeb from'./assets/villapeb.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-section">
        <h3>Atendimento ao cliente</h3>
        <a href='https://g.co/kgs/YsiHQye' target="_blank" rel="noopener noreferrer"><p>Fale conosco</p></a>
        <a href='https://www.tripadvisor.com.br/Restaurant_Review-g303638-d15075592-Reviews-Villa_Veron_Pizzaria_Petiscaria-Aracaju_State_of_Sergipe.html?m=19905' target="_blank" rel="noopener noreferrer"><p> <i class="fa-solid fa-star"></i> Avalie-nos</p></a>
      </div>
      <div className="footer-section">
        <img src={villapeb} alt="Villa Veron Logo" className="footer-logo" />
        <p>© 2024 VILLA VERON PIZZARIA LTDA.</p>
        <p>CNPJ: 25.033.726/0001-51</p>
        <p>Todos os direitos reservados.</p>
        <a href="https://www.instagram.com/rgtechin/" target="_blank" rel="noopener noreferrer">
            <span className="social-text">Desenvolvido por RG Tech</span>
          </a>
      </div>
      <div className="footer-section">
        <h3>Conecte-se com a Villa!</h3>
        <div className="social-icons">
          <a href="https://www.instagram.com/villaveron" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="2x" className="social-icon" />
            <span className="social-text">@villaveron</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
