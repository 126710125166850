import React, { useState, useEffect } from 'react';
import './LoadingScreen.css';
import LogoScreen from './assets/logo.png';

const LoadingScreen = ({ onLoaded }) => {
    const [phase, setPhase] = useState(0);

    useEffect(() => {
      const phases = [
        { time: 1000, action: () => setPhase(1) },  // Mudar para branco após 1 segundo
        { time: 2500, action: () => setPhase(2) },  // Mostrar logo após mais 1.5 segundo
        { time: 6000, action: () => setPhase(3) },  // Iniciar efeito de desligamento após mais 3.5 segundos
        { time: 6500, action: () => onLoaded(4) }    // Terminar a tela de carregamento após mais 0.5 segundo
      ];

      phases.forEach(({ time, action }) => {
        setTimeout(action, time);
      });
    }, [onLoaded]);

    return (
      <div className={`loading-screen phase-${phase}`}>
        <div className="logo-container">
          <img src={LogoScreen} alt="Logo" className="logo-screen" />
        </div>
      </div>
    );
};

export default LoadingScreen;
