import React, { useState, useEffect } from 'react';
import './Story.css';
import img1 from './assets/carousel/1.webp';
import img2 from './assets/carousel/2.webp';
import img3 from './assets/carousel/3.webp';
import img4 from './assets/carousel/4.webp';
import img5 from './assets/carousel/5.webp';
import img6 from './assets/carousel/6.webp';
import img7 from './assets/carousel/7.webp';
import img8 from './assets/carousel/8.webp';
import img9 from './assets/carousel/9.webp';
import img10 from './assets/carousel/10.webp';
import img11 from './assets/carousel/11.webp';
import img12 from './assets/carousel/12.webp';
import logoverde from './assets/villaverde.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12];

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 1024 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 1024, min: 768 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
    },
};

const Story = () => {
    const [loadedImages, setLoadedImages] = useState({});

    useEffect(() => {
        images.forEach((src, index) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                setLoadedImages(prev => ({ ...prev, [index]: src }));
            };
            img.onerror = () => {
                console.error(`Failed to load image at index ${index}`);
            };
        });
    }, []);

    return (
        <div className="nossa-historia" id='Story'>
            <div className="storymenu">
                <div className="nossa-historia-banner">Nossa história</div>
                <h2 className='h2-story'>A ARTE DE FAZER PIZZA</h2>
            </div>
            <div className="carousel-container">
                <Carousel 
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    showDots={false}
                >
                    {images.map((image, index) => (
                        <div className="carousel-item" key={index}>
                            {loadedImages[index] ? (
                                <img src={image} alt={`Imagem ${index + 1}`} />
                            ) : (
                                <div className="image-placeholder"></div>
                            )}
                        </div>
                    ))}
                </Carousel>
            </div>
            <p className="description">
                O nome Villa Veron foi criado em homenagem a Profa. Vera Cristina, mãe de quatro filhos homens,
sempre sonhou em ter uma garotinha, daí então, surgiu o nome Villa Veron, sua filha mais nova, a qual sempre
foi cuidada com muito amor e carinho, pensada em cada detalhe, desde os nomes das pizzas, homenageando
os grandes poetas brasileiros, até o seu design feito para transmitir a sensação de estarmos sendo acolhidos pelo abraço
de mãe, representando um ambiente seguro, leve, feliz e acolhedor, uma das mais belas características marcantes 
de Profa. Vera.
            </p>
            <div className="logo-container-menu">
                <img src={logoverde} alt="Logo Villa Veron" className="logoverde" />
            </div>
        </div>
    );
}

export default Story;
