import React, { useState } from 'react';
import './App.css';
import Header from './Header';
import Menu from './Menu';
import Story from './Story';
import Locations from './Locations';
import Footer from './Footer';
import LoadingScreen from './LoadingScreen';

const App = () => {
  const [loading, setLoading] = useState(true);

  const handleLoaded = () => {
    setLoading(false);
  };

  return (
    <div className="App">
      {loading && <LoadingScreen onLoaded={handleLoaded} />}
      <div className={`content ${loading ? 'hidden' : ''}`}>
        <Header />
        <Menu />
        <Story />
        <Locations />
        <Footer />
      </div>
    </div>
  );
};

export default App;
