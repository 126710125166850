import React from 'react';
import './Locations.css';

function Locations() {
  return (
    <div className="locations-container" id='Locals'>
      <div className="headerlocation">
      <div className="locais-banner">Locais e horários</div>
      </div>
      <h1 className='h1-local'>VENHA PEGAR SUA FATIA!</h1>
      <div className="locationsdown">
        <div className="locationdown">
          <h2>VILLA VERON</h2>
          <p>R. Zaqueu Brandao 598, Sao José, Aracaju - SE</p>
          <p> <i class="fa-brands fa-whatsapp fa-lg" aria-hidden="true"></i> (79) 3085-8555</p>
          <p><i class="fa-solid fa-phone" aria-hidden="true"></i> (79) 99991-8555</p>
          <p>ABERTO DE TERÇA A DOMINGO</p>
          <p>DAS 17H30 ÀS 23:00</p>
          <p>DELIVERY - TERÇA A DOMINGO</p>
          <p>DAS 17H30 ÀS 22:30</p>
          <a href='https://maps.app.goo.gl/hiWAa17xBdmKoSMg8' target='_blank' without rel="noreferrer">
          <button className='menu-button2'>COMO CHEGAR</button>
          </a>
        </div>
        <div className="locationdown">
          <h2>VILLA VERON BARRA</h2>
          <p>Rodovia José de Campos 280, Centro, Barra dos Coqueiros - SE</p>
          <p><i class="fa-brands fa-whatsapp fa-lg" aria-hidden="true"></i> (79) 30000-0000</p>
          <p><i class="fa-solid fa-phone" aria-hidden="true"></i> (79) 90000-0000</p>
          <p>ABERTO DE TERÇA A DOMINGO</p>
          <p>DAS 17H30 ÀS 23:00</p>
          <p>DELIVERY - TERÇA A DOMINGO</p>
          <p>DAS 17H30 ÀS 22:30</p>
          <a href='https://maps.app.goo.gl/hiWAa17xBdmKoSMg8' target='_blank' without rel="noreferrer">
          <button className='menu-button2'> COMO CHEGAR</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Locations;
