import React, { useState } from 'react';
import './Header.css';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <nav className="navbar">
        <div className={`menu-toggle ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={`navbar-links ${menuOpen ? 'show' : ''}`}>
          <li><a href="#Menu" onClick={toggleMenu}>Início</a></li>
          <li><a href="#Locals" onClick={toggleMenu}>Locais e Horários</a></li>
          <li><a href="#Story" onClick={toggleMenu}>Sobre nós</a></li>
        </ul>
        <div className="navbar-icons">
          <a href="https://www.instagram.com/villaveron/" target='_blank'><i className="fab fa-instagram"></i></a>
          <a href='https://api.whatsapp.com/send?phone=557930858555&text=Olá.' target='_blank'><i class="fa-brands fa-whatsapp" aria-hidden="true"></i></a>
        </div>
      </nav>
    </header>
  );
}

export default Header;
